import React, { Fragment } from 'react';
import { ImageCarousel } from '../../../common/ImageCarousel';
import { TeamMember } from '../TeamMember';
import { HubAudioPlayer } from '../../../common/HubAudioPlayer';
import { Wrapper } from '../../../layout/Wrapper';

import { ReactComponent as TapeSvg } from '../../../../images/common/music-track.svg';
import Henry from '../../../../images/common/Henry.png';

import styles from './NewsIntro.module.scss';

const NewsIntro = ({ title, intro, introAudio, slides, members, refs }) => {
  const pageRefs = refs;

  const getPageRef = index => {
    return pageRefs[index];
  };

  return (
    <div>
      <Wrapper wrapped padded>
        <div className={styles.topBanner}>
          <div className={styles.title}>
            <h1>{title}</h1>
          </div>
          <div
            className={styles.introText}
            dangerouslySetInnerHTML={{
              __html: intro,
            }}
          />
          <div className={styles.introAudio}>
            <HubAudioPlayer src={introAudio?.localFile?.publicURL} label={'Listen'} />
          </div>
        </div>
      </Wrapper>
      <Wrapper wrapped padded>
        <div className={styles.carousel}>
          <ImageCarousel images={slides} />
        </div>
      </Wrapper>
      <Wrapper id="intro" wrapped padded>
        <div className={styles.meetTitle}>
          <h2>Meet the Team</h2>
        </div>
        <div className={styles.members}>
          {members &&
            members.map((member, index) => (
              <Fragment key={`team-member-${member.name}`}>
                <TeamMember member={member} pageRef={getPageRef(index)} />
              </Fragment>
            ))}
        </div>
        <div className={styles.introBottom}>
          <div>
            <TapeSvg className={styles.bg} preserveAspectRatio="xMinYMin" />
            <div className={styles.introBottomInfo}>
              <p>These boxes shows Castro tracks for each page</p>
            </div>
          </div>
          <div>
            <div>
              <img src={Henry} alt="Henry" />
            </div>
            <div className={styles.introBottomInfo}>
              <p>Henry the support bat is our mascot who you’ll find on each page…</p>
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export { NewsIntro };
