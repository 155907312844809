import React from 'react';
import Img from 'gatsby-image';
import classNames from 'classnames';
import OrangeNote from '../../../../images/common/orange-note.png';
import RedNote from '../../../../images/common/red-note.png';
import BlueNote from '../../../../images/common/blue-note.png';
import YellowNote from '../../../../images/common/yellow-note.png';
import { InternalExternalLink } from '../../../common/InternalExternalLink';

import styles from './LHSImages.module.scss';

const LHSImages = ({ lhsImages, numColumns, numMobileColumns }) => {
  const cls = classNames(
    { [styles.oneMobileColumn]: numMobileColumns === '1' },
    { [styles.twoMobileColumns]: numMobileColumns === '2' },
    { [styles.oneColumn]: numColumns === '1' },
    { [styles.twoColumns]: numColumns === '2' },
    { [styles.threeColumns]: numColumns === '3' },
    styles.lhsImages,
  );

  const getCorrectIcon = index => {
    if (index === 0) {
      return (
        <span className={styles.noteIcon}>
          <img src={OrangeNote} alt="Orange Note" />
        </span>
      );
    }

    if (index === 1) {
      return (
        <span className={styles.noteIcon}>
          <img src={RedNote} alt="Red Note" />
        </span>
      );
    }

    if (index === 2) {
      return (
        <span className={styles.noteIcon}>
          <img src={BlueNote} alt="Blue Note" />
        </span>
      );
    }

    if (index === 3) {
      return (
        <span className={styles.noteIcon}>
          <img src={YellowNote} alt="Yellow Note" />
        </span>
      );
    }
  };

  return (
    <div className={cls}>
      {lhsImages.map((item, imageIndex) => (
        <div key={`lhs-image-${imageIndex}`}>
          {/* LHS Image */}
          {item.image?.localFile?.childImageSharp && (
            <div>
              <Img fluid={item.image?.localFile?.childImageSharp?.fluid} alt={item.image?.altText} />
            </div>
          )}

          {/* Image Title */}
          {item.title && <div className={styles.imageTitle}>{item.title}</div>}

          {/* Audio Links */}
          {item.audioLinks && (
            <div className={styles.audioLinks}>
              {item.audioLinks.map((link, index) => (
                <div className={styles.songArtistLink} key={`link-${index}`}>
                  <InternalExternalLink to={link.linkUrl}>
                    {getCorrectIcon(imageIndex)}
                    <span>{link.linkTitle}</span>
                  </InternalExternalLink>
                </div>
              ))}
            </div>
          )}

          {item.audioLinksQuote && <div className={styles.audioLinksQuote}>{item.audioLinksQuote}</div>}
        </div>
      ))}
    </div>
  );
};

export { LHSImages };
