import React from 'react';
import Img from 'gatsby-image';
import { HubAudioPlayer } from '../../../common/HubAudioPlayer';

import styles from './TeamMember.module.scss';

const TeamMember = ({ member, pageRef }) => {
  const executeScroll = () => pageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  return (
    <div className={styles.member}>
      <div className={styles.memberImage} onClick={executeScroll}>
        <Img fluid={member.portrait?.localFile?.childImageSharp?.fluid} alt={member.portrait?.altText} />
      </div>
      <div className={styles.memberInfo}>
        <div>
          <h3>{member.name}</h3>
        </div>
        <div>{member.description}</div>
        <div className={styles.memberAudio}>
          <HubAudioPlayer src={member.audioDescription.localFile?.publicURL} label={'Listen'} />
        </div>
      </div>
    </div>
  );
};

export { TeamMember };
