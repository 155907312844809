import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import SEO from '../components/common/seo';
import { getCategoryColor } from '../components/context/AppContext';
// import { Wrapper } from '../components/layout/Wrapper';

// import { Title } from '../components/common/Title';
// import { getFeaturedImageSrc, getImageSrc } from '../utils/data';
// import { InternalExternalLink } from '../components/common/InternalExternalLink';

import { NewsIntro } from '../components/pagelayout/newspaper/NewsIntro';
import { NewsCredits } from '../components/pagelayout/newspaper/NewsCredits';
import { NewsPage } from '../components/pagelayout/newspaper/NewsPage';

import styles from './Newspaper.module.scss';

export const pageQuery = graphql`
  query NewspaperPageQuery {
    newspaper: wpPage(slug: { eq: "newspaper" }) {
      gameTitle {
        displayTitle
      }
      content
      audioAlternative {
        shortDescriptionAudioAlternative {
          localFile {
            publicURL
          }
          sourceUrl
        }
      }
      gameThreeSlider {
        slides {
          image {
            localFile {
              childImageSharp {
                fluid(maxHeight: 960) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
      gameThreeTeam {
        teamMembers {
          name
          description
          audioDescription {
            localFile {
              publicURL
            }
          }
          portrait {
            localFile {
              childImageSharp {
                fluid(maxWidth: 320) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
      gameThreeCredits {
        credits
        logo {
          localFile {
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
      gameThreePages {
        pages {
          pageTitle
          firstLetterImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 75) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            altText
          }
          introduction
          introductionAudio {
            localFile {
              publicURL
            }
          }
          body
          bodyAudio {
            localFile {
              publicURL
            }
          }
          lhsImageColumns
          lhsImageMobileColumns
          lhsImages {
            image {
              localFile {
                childImageSharp {
                  fluid(maxHeight: 640) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              altText
            }
            title
            audioLinks {
              linkUrl
              linkTitle
            }
            audioLinksQuote
          }
          musicLinkTitle
          musicLink
          rhsImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 960) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            altText
          }
          rhsImageAudioAlternative {
            mediaItemUrl
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;

const NewspaperPage = ({ data }) => {
  const { newspaper } = data;

  const pageRefs = [];
  pageRefs[0] = useRef(null);
  pageRefs[1] = useRef(null);
  pageRefs[2] = useRef(null);
  pageRefs[3] = useRef(null);
  pageRefs[4] = useRef(null);

  const homeRef = useRef(null);

  const getNextRef = index => {
    if (index === pageRefs.length - 1) {
      return pageRefs[0];
    } else {
      return pageRefs[index + 1];
    }
  };
  return (
    <>
      <SEO title="Newspaper" />
      <Helmet
        bodyAttributes={{
          class: getCategoryColor(),
        }}
      />
      <div ref={homeRef}>
        <section className={styles.intro}>
          <NewsIntro
            title={newspaper.gameTitle.displayTitle}
            intro={newspaper.content}
            introAudio={newspaper.audioAlternative.shortDescriptionAudioAlternative}
            slides={newspaper.gameThreeSlider.slides}
            members={newspaper.gameThreeTeam.teamMembers}
            refs={pageRefs}
          />
        </section>

        <section className={styles.pages}>
          {newspaper.gameThreePages.pages.map((page, index) => (
            <article key={`page-${page.pageTitle}`} ref={pageRefs[index]}>
              <NewsPage page={page} homeRef={homeRef} nextRef={getNextRef(index)} />
            </article>
          ))}
        </section>

        <section className={styles.credits}>
          <NewsCredits credits={newspaper.gameThreeCredits.credits} logo={newspaper.gameThreeCredits.logo} />
        </section>
      </div>
    </>
  );
};
export default NewspaperPage;
