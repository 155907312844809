import React, { useCallback, useRef } from 'react';
import Img from 'gatsby-image';
import classNames from 'classnames';

import { Slider } from '../Slider';
import { Icon } from '../Icon/Icon';
import styles from './ImageCarousel.module.scss';

const slideClasses = [styles.slideOne, styles.slideTwo, styles.slideThree];

const ImageCarousel = ({ images, className }) => {
  // const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef(null);

  const onRef = useCallback(ref => {
    if (ref) {
      sliderRef.current = ref;
    }
  }, []);

  const onPrev = useCallback(() => {
    if (sliderRef.current) sliderRef.current.slider.goTo('prev');
  }, []);

  const onNext = useCallback(() => {
    if (sliderRef.current) sliderRef.current.slider.goTo('next');
  }, []);

  return (
    <>
      <Slider
        className={classNames(styles.wrapper, className)}
        onRef={onRef}
        settings={{ controls: false, nav: false, mouseDrag: false, edgePadding: 16, gutter: 100, center: true }}
        onIndexChanged={evt => {
          // setActiveIndex(evt.displayIndex - 1);
        }}
      >
        {images.map((image, index) => (
          <div
            className={classNames(styles.slide, slideClasses[index % slideClasses.length])}
            key={`video-carousel-item-${index}`}
          >
            <div className={styles.slideAspectRatio}>
              <div className={styles.slideInner}>
                <Img fluid={image.image?.localFile?.childImageSharp?.fluid} alt={image.image.altText} />
                {/* <VideoEmbed
                  url={video.videoEmbedLink}
                  alt={video.alternativeText}
                  poster={video.videoStill?.localFile?.publicURL}
                  active={index === activeIndex}
                /> */}
              </div>
            </div>
          </div>
        ))}
      </Slider>

      <div className={styles.controls}>
        <button className={classNames(styles.control, styles.controlPrev)} onClick={onPrev} aria-label="next">
          <Icon size="free" name="arrow-drawn" mirror />
        </button>
        <button className={classNames(styles.control, styles.controlNext)} onClick={onNext} aria-label="next">
          <Icon size="free" name="arrow-drawn" />
        </button>
      </div>
    </>
  );
};

export { ImageCarousel };
