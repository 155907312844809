import React from 'react';
import Img from 'gatsby-image';
import { Wrapper } from '../../../layout/Wrapper';
import styles from './NewsCredits.module.scss';

const NewsCredits = ({ credits, logo }) => {
  return (
    <section className={styles.credits}>
      <Wrapper id="credits" wrapped padded className={styles.noVPadding}>
        <div className={styles.creditsInner}>
          <div>
            <Img
              fluid={logo.localFile?.childImageSharp?.fluid}
              alt={logo.portrait?.altText}
              className={styles.creditsLogo}
            />
          </div>
          <div className={styles.creditsTextBlock}>
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: credits,
                }}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    </section>
  );
};

export { NewsCredits };
