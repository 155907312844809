import React from 'react';
import Img from 'gatsby-image';
import { HubAudioPlayer } from '../../../common/HubAudioPlayer';
import { LHSImages } from '../LHSImages';
import { Wrapper } from '../../../layout/Wrapper';
import { InternalExternalLink } from '../../../common/InternalExternalLink';
import { ReactComponent as SongArtist } from '../../../../images/common/music-link.svg';

import styles from './NewsPage.module.scss';

const NewsPage = ({ page, homeRef, nextRef }) => {
  const executeScrollHome = () => homeRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  const executeScrollNext = () => nextRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  return (
    <>
      <Wrapper id="intro" wrapped padded className={styles.pageWrapper}>
        <div className={styles.titleBlock}>
          <span className={styles.headerLink} onClick={executeScrollHome}>
            Back to Top
          </span>
          <span className={styles.titleText}>{page.pageTitle}</span>
          <span className={styles.headerLink} onClick={executeScrollNext}>
            Next
          </span>
        </div>
        <div className={styles.page}>
          {/* Intro */}
          <div className={styles.a}>
            <div className={styles.intro}>
              <div>
                <div>
                  <span className={styles.firstLetterContainer}>
                    <Img
                      className={styles.firstLetter}
                      fluid={page.firstLetterImage?.localFile?.childImageSharp?.fluid}
                      alt={page.firstLetterImage.portrait?.altText}
                    />
                  </span>
                  <span>&nbsp;{page.introduction}</span>
                </div>
              </div>
              <div className={styles.introAudio}>
                <HubAudioPlayer src={page.introductionAudio?.localFile?.publicURL} label={'Listen'} />
              </div>
            </div>
          </div>
          {/* Image */}
          <div className={styles.b}>
            {page.rhsImage?.localFile?.childImageSharp && (
              <div className={styles.imageBox}>
                <Img fluid={page.rhsImage?.localFile?.childImageSharp?.fluid} alt={page.rhsImage?.altText} />
              </div>
            )}

            {page.rhsImageAudioAlternative && (
              <div className={styles.rhsAudio}>
                <HubAudioPlayer src={page.rhsImageAudioAlternative?.localFile?.publicURL} label={'Listen'} />
              </div>
            )}
          </div>
          {/* Body */}
          <div className={styles.c}>
            <div className={styles.pageBody}>
              <div
                dangerouslySetInnerHTML={{
                  __html: page.body,
                }}
              />
              {page.bodyAudio?.localFile?.publicURL && (
                <div>
                  <HubAudioPlayer src={page.bodyAudio?.localFile?.publicURL} label={'Listen'} />
                </div>
              )}
            </div>
            {page.lhsImages && (
              <div className={styles.leftImages}>
                <LHSImages
                  lhsImages={page.lhsImages}
                  numColumns={page.lhsImageColumns}
                  numMobileColumns={page.lhsImageMobileColumns}
                />
              </div>
            )}
            {page.musicLink && (
              <div className={styles.musicLink}>
                <InternalExternalLink to={page.musicLink} newTab>
                  <SongArtist className={styles.bg} preserveAspectRatio="xMinYMin" />
                  <div className={styles.musicLinkTitle}>
                    <div>{page.musicLinkTitle}</div>
                  </div>
                </InternalExternalLink>
              </div>
            )}
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export { NewsPage };
